import type { GaVueComponent } from "@/common/vueUtils";
import { createParentComponent } from "@/common/vueUtils";
import { createStore, type Store } from "@/common/storeUtils";
import { GaButton } from "@/components/general/GaButton";
import "./GaModal.scss";
import { onKeyUp } from "@vueuse/core/index";
import { UseFocusTrap } from "@vueuse/integrations/useFocusTrap/component";
import { TransitionGroup } from "vue";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";

type ModalSize = "lg" | "xl";

export type ConfirmModalConfig = {
  title?: string;
  message: string;
  confirmLabel?: string;
  cancelLabel?: string;
};

export type CustomModalConfig = {
  title?: string;
  body: () => GaVueComponent;
  footer?: () => GaVueComponent;
  size?: ModalSize;
};

type GaModalState = {
  kind: "CONFIRM_MODAL";
  title: string | null;
  body: () => GaVueComponent;
  footer?: () => GaVueComponent;
  close: () => void;
  size?: ModalSize;
};

function afterEachChange(newState: GaModalState | null): void {
  const backdropClass = "modal-open";
  const body = document.querySelector("body");
  if (newState === null) {
    body?.classList.remove(backdropClass);
  } else {
    body?.classList.add(backdropClass);
  }
}

namespace GaModal {
  export function open(store: Store<GaModalState | null>, newState: GaModalState): void {
    store.set(newState);
    afterEachChange(newState);
  }

  export function close(store: Store<GaModalState | null>): void {
    store.set(null);
    afterEachChange(null);
  }
}

const store = createStore<GaModalState | null>(null);

export function useCustomModal(): (config: CustomModalConfig) => Promise<void> {
  return (config) => {
    return new Promise((resolve) => {
      function cancel(): void {
        GaModal.close(store);
        resolve();
      }

      const title = config.title === undefined || config.title.trim().length === 0 ? null : config.title;
      GaModal.open(store, {
        kind: "CONFIRM_MODAL",
        title: title,
        body: config.body,
        close: cancel,
        footer: config.footer,
        size: config.size,
      });
    });
  };
}

export function useConfirmationModal(): (config: ConfirmModalConfig) => Promise<boolean> {
  const { t } = useGaContext();
  return (config) => {
    return new Promise((resolve) => {
      const title = config.title === undefined || config.title.trim().length === 0 ? null : config.title;
      const cancelLabel = config.cancelLabel === undefined || config.cancelLabel.trim().length === 0 ? null : config.cancelLabel;
      const confirmLabel = config.confirmLabel === undefined || config.confirmLabel.trim().length === 0 ? null : config.confirmLabel;

      function cancel(): void {
        GaModal.close(store);
        resolve(false);
      }

      function confirm(): void {
        GaModal.close(store);
        resolve(true);
      }

      GaModal.open(store, {
        kind: "CONFIRM_MODAL",
        title,
        body: () => <>{config.message}</>,
        footer: () => (
          <>
            <GaButton onClick={cancel} variant="primary-alt">
              {cancelLabel ?? t("general:button.cancel")}
            </GaButton>
            <GaButton onClick={confirm}>{confirmLabel ?? t("general:button.confirm")}</GaButton>
          </>
        ),
        close: cancel,
      });
    });
  };
}

function getModalSizeClass(size: ModalSize): string {
  switch (size) {
    case "lg": {
      return "modal-lg";
    }
    case "xl": {
      return "modal-xl";
    }
  }
}

function GaConfirmationModal(props: { state: GaModalState }): GaVueComponent {
  const labelId = "modalLiveLabel";
  const labelledby = props.state.title !== null ? labelId : undefined;

  onKeyUp("Escape", () => props.state.close());

  return (
    <div class="modal d-block" tabindex="-1" aria-modal="true" role="dialog" aria-labelledby={labelledby}>
      <div class={["modal-dialog", props.state.size !== undefined ? getModalSizeClass(props.state.size) : ""]}>
        <UseFocusTrap
          options={{
            clickOutsideDeactivates: () => {
              props.state.close();
              return true;
            },
          }}>
          <div class="modal-content">
            <div class="modal-header">
              {props.state.title !== null ? (
                <h5 class="modal-title" id={labelId}>
                  {props.state.title}
                </h5>
              ) : null}
              <button onClick={props.state.close} type="button" class="btn-close" aria-label="Close"></button>
            </div>
            <div class="modal-body">{props.state.body()}</div>
            {props.state.footer !== undefined ? <div class="modal-footer">{props.state.footer()}</div> : null}
          </div>
        </UseFocusTrap>
      </div>
    </div>
  );
}

export function GaModalContainer(): GaVueComponent {
  const unpackedStore = store.unpackNull();
  return (
    <TransitionGroup name="modal">
      {unpackedStore !== null ? (
        <>
          <GaConfirmationModal state={unpackedStore.get()} key="modal" />
          <div class="modal-backdrop" key="modal-backdrop"></div>
        </>
      ) : null}
    </TransitionGroup>
  );
}

export type ModalDialogProps = {
  title: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
};
export const GaConfirmModal = createParentComponent<ModalDialogProps>((props) => {
  const { t } = useGaContext();
  onKeyUp("Escape", () => props.onCancel());

  return (
    <div class="modal d-block" tabindex="-1" aria-modal="true" role="dialog" aria-labelledby={props.title}>
      <div class="modal-dialog">
        <UseFocusTrap
          options={{
            clickOutsideDeactivates: () => {
              props.onCancel();
              return true;
            },
          }}>
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id={props.title}>
                {props.title}
              </h5>
              <button onClick={props.onCancel} type="button" class="btn-close" aria-label="Close"></button>
            </div>
            <div class="modal-body">{props.children}</div>
            <div class="modal-footer">
              <GaButton onClick={props.onCancel} variant="primary-alt">
                {props.cancelLabel ?? t("general:button.cancel")}
              </GaButton>
              <GaButton onClick={props.onConfirm}>{props.confirmLabel ?? t("general:button.confirm")}</GaButton>
            </div>
          </div>
        </UseFocusTrap>
      </div>
    </div>
  );
});
