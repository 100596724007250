import { useParams } from "@splitMigrate/vue-router";

import { Language } from "@utils/type/type";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";

export const useCorporateIdFromParam = (): number | null => {
  const params = useParams();
  if ("corporateId" in params && typeof params.corporateId === "string") {
    return parseInt(params.corporateId);
  }
  return null;
};

export const useEnrichUrl = (url: string): string => {
  if (Language.getUrlLanguage(url) !== null || url.includes(".")) {
    return url;
  }
  const { currentLanguage } = useGaContext();
  return "/" + currentLanguage() + url;
};

export const isRouterUrl =
  (routerPaths: string[]) =>
  (url: string): boolean => {
    let result = false;
    for (const path of routerPaths) {
      if (result) {
        break;
      }
      const splittedPath = path
        .replace(/^\/|\/$/g, "")
        .split("/")
        .filter((part, i) => i > 0 || part.match(/:lang\??/) == null);
      const splittedUrl = url
        .replace(/^\/|\/$/g, "")
        .replace(/\?[^?]*$/g, "")
        .split("/")
        .filter((part) => !part.startsWith("#"))
        .filter((part, i) => {
          // ignore the first part, if it is a language ISO code
          return !(i === 0 && Language.getValues().includes(part as Language));
        });
      if (splittedPath.length === splittedUrl.length) {
        for (const [key, subPath] of splittedPath.entries()) {
          if (subPath.startsWith(":") || subPath === splittedUrl[key]) {
            result = true;
          } else {
            result = false;
            break;
          }
        }
      }
    }
    return result;
  };
