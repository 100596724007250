import type { ValidationErrorReasons } from "@newgenerated/shared/schema";
import type { DeepReadonly } from "vue";
import type { Store } from "@/common/storeUtils";

type Dot<T extends string, U extends string> = "" extends U ? T : `${T}.${U}`;
type NestedDotKeys<T> = T extends readonly unknown[]
  ? NestedDotKeys<T[number]>
  : {
      [K in keyof T & string]: K | Dot<K, NestedDotKeys<T[K]>>;
    }[keyof T & string];

export function getFieldError<T>(validationErrorReasons: DeepReadonly<ValidationErrorReasons>, fieldName: NestedDotKeys<T>): DeepReadonly<string[]> {
  return validationErrorReasons.fields.additionalProperties[fieldName] ?? [];
}

export function resetFormValidation<T>(store: Store<ValidationErrorReasons>, fieldName: NestedDotKeys<T>): void {
  store.sub("fields").sub("additionalProperties").sub(fieldName).set([]);
}
