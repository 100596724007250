import type { GaVueComponent } from "@/common/vueUtils";
import { type LocalDate, LocalDateUtils } from "@utils/type/LocalDate";
import type { Store } from "@/common/storeUtils";
import type { DeepReadonly } from "vue";

export function GaFormFieldInputDate(props: { store: Store<LocalDate | null>; name: string; placeholder?: string; required?: boolean; label?: string; help?: string; errors?: DeepReadonly<string[]>; disabled?: boolean }): GaVueComponent {
  const value = props.store.get();
  const errors = props.errors ?? [];
  const disabled = props.disabled ?? false;
  return (
    <div class="mb-3">
      {props.label !== undefined && props.label.trim().length > 0 ? (
        <label class="form-label" for={props.name}>
          {props.label}
        </label>
      ) : null}
      <input
        type="date"
        value={value !== null ? LocalDateUtils.formatToIso(value) : null}
        name={props.name}
        class="form-control"
        id={props.name}
        placeholder={props.placeholder}
        required={props.required === true}
        onInput={(e) => {
          const { target } = e;
          if (target instanceof HTMLInputElement) {
            const date = target.value === "" ? null : LocalDateUtils.parse(target.value);
            props.store.set(date);
          }
        }}
        disabled={disabled}
      />
      {errors.map((error) => (
        <div class="invalid-feedback">{error}</div>
      ))}
      {props.help !== undefined && props.help.trim().length > 0 ? <div class="form-text">{props.help}</div> : null}
    </div>
  );
}
