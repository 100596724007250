import { DateTime } from "luxon";
import { assert } from "@utils/assertion";
import type { Language } from "@utils/type/type";

const DATE_ISO = "yyyy-LL-dd" as const;
const DATE_SHORT = "L/d/yy" as const;
const DATE_MEDIUM = "LLL d, yyyy" as const;
const DATE_LONG = "LLLL d, yyyy" as const;
const DATE_EN_US = "MMM d, yyyy" as const;
const DATE_DE_DE = "dd.MM.yyyy" as const;
const DATE_FR_FR = "d MMM yyyy" as const;
const DATE_ES_MX = "d MMM. yyyy" as const;
const DATE_PT_BR = "d 'de' MMM 'de' yyyy" as const;
const DATE_ZH_CN = "yyyy年M月d日";
const DATE_RU_RU = "d MMM yyyy г.";

type LocalDateFormat = typeof DATE_ISO | typeof DATE_SHORT | typeof DATE_MEDIUM | typeof DATE_LONG | typeof DATE_EN_US | typeof DATE_DE_DE | typeof DATE_FR_FR | typeof DATE_ES_MX | typeof DATE_PT_BR | typeof DATE_ZH_CN | typeof DATE_RU_RU;

const dateTimeFormats: { [lang in Language]: LocalDateFormat } = {
  en: DATE_EN_US,
  de: DATE_DE_DE,
  fr: DATE_FR_FR,
  es: DATE_ES_MX,
  pt: DATE_PT_BR,
  zh: DATE_ZH_CN,
  ru: DATE_RU_RU,
};

export type LocalDate = {
  year: number;
  month: number;
  day: number;
};

const fromDateTime = (dateTime: DateTime): LocalDate => {
  return { year: dateTime.year, month: dateTime.month, day: dateTime.day };
};

const parse = (date: string, format?: LocalDateFormat): LocalDate => {
  const dt = DateTime.fromFormat(date, format ?? DATE_ISO);
  assert(dt.isValid, `could not parse date: ${date}`);
  return fromDateTime(dt);
};

const formatByPattern = (date: LocalDate, format: string): string => {
  return DateTime.utc(date.year, date.month, date.day, 0, 0, 0, 0).toFormat(format);
};

const formatToIso = (date: LocalDate): string => {
  return DateTime.utc(date.year, date.month, date.day, 0, 0, 0, 0).toFormat(DATE_ISO);
};

const formatWithLocale = (date: LocalDate, language: Language): string => {
  const format = dateTimeFormats[language];
  return DateTime.utc(date.year, date.month, date.day, 0, 0, 0, 0).toFormat(format, { locale: language });
};

const now = (): LocalDate => {
  return fromDateTime(DateTime.now());
};

export const LocalDateUtils = {
  parse,
  fromDateTime,
  formatToIso,
  formatByPattern,
  now,
  formatWithLocale,
  DATE_ISO,
  DATE_SHORT,
  DATE_MEDIUM,
  DATE_LONG,
};
