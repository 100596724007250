import { assert } from "@utils/assertion";
import { registerModule } from "@/Registry";
import { load } from "recaptcha-v3";

export async function initBotProtection(element: Element): Promise<void> {
  const recaptcha = await load("6Lf9SoAlAAAAAEamErYdj0HurO2nRxIk5UhQXjcW");

  assert(element instanceof HTMLFormElement);

  element.onsubmit = async (e) => {
    e.preventDefault();
    const token = await recaptcha.execute();
    const hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.name = "g-recaptcha-response";
    hiddenInput.value = token;
    element.appendChild(hiddenInput);
    element.submit();
  };
}

registerModule("[data-ga-bot-protection]", initBotProtection);
