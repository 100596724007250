import { useRoute, useRouter } from "vue-router";
import { isRouterUrl } from "@utils/routeUtils";

export const useParams = (): Record<string, string | string[]> => {
  return useRoute().params;
};

export const usePath = (): string => {
  return useRoute().path;
};

export const useIsRouterUrl = (url: string): boolean => {
  const router = useRouter().getRoutes();
  return isRouterUrl(router.map((route) => route.path))(url);
};

export { RouterLink } from "vue-router";
